import { useState, useEffect } from 'react';

const useLocalStorageWithDefault = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
        try {
          return JSON.parse(savedValue);
        } catch (e) {
          return savedValue;
        }
      } else {
        return defaultValue;
      }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorageWithDefault;
