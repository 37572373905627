import React from "react";
import { CatFishSvg, CatSvg, DogSvg, FishSvg, SheepSvg } from "../../utils/pet-svgs";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Bestiary = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Bestiary</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <div>
          <h2 className="text-2xl font-bold m-4">Cat</h2>
          <CatSvg />
        </div>
        <div>
          <h2 className="text-2xl font-bold m-4">Fish</h2>
          <FishSvg />
        </div>
        <div>
          <h2 className="text-2xl font-bold m-4">Sheep</h2>
          <SheepSvg />
        </div>
        <div>
          <h2 className="text-2xl font-bold m-4">Dog</h2>
          <DogSvg />
        </div>
        <div>
          <h2 className="text-2xl font-bold m-4">Example CatFish</h2>
          <CatFishSvg />
        </div>
      </div>
    </div>
  );
};

export default Bestiary;
