import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CreatePet from "./components/create-pet/create-pet";
import About from "./pages/about/about";
import Bestiary from "./pages/bestiary/bestiary";
import Blog from "./pages/blog/blog";
import BlogPost from "./pages/blog-post/blog-post";
import Header from "./components/header/header";
import Profile from "./components/auth/profile/profile";
import { ToastProvider } from "./ToastContext";

const App = () => {
  return (
    <ToastProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<CreatePet />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/bestiary" element={<Bestiary />} />
        </Routes>
      </Router>
    </ToastProvider>
  );
};

export default App;
