import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import LoginButton from '../auth/login-button/login-button';
import LogoutButton from '../auth/logout-button/logout-button';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      showInstallPromotion();
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const showInstallPromotion = () => {
    const installButton = document.getElementById('installButton');
    if (installButton) {
      installButton.style.display = 'block';
    }
  };

  const installApp = () => {
    if (!deferredPrompt) {
      return;
    }
    const installButton = document.getElementById('installButton');
    if (installButton) {
      installButton.style.display = 'none';
    }
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
    });
  };

  return (
    <header className="bg-darkslategrey text-white px-4 w-full box-border">
      <div className="container mx-auto flex justify-between items-center">
        <div><a href='/'><Logo  className="h-28 w-28 mr-0" /></a></div>
        <nav className={`flex space-x-4 ${isOpen ? 'block' : 'hidden'} md:flex`}>
          <Link to="/" className="text-white hover:text-gray-400">Home</Link>
          <Link to="/about" className="text-white hover:text-gray-400">About Us</Link>
          <Link to="/blog" className="text-white hover:text-gray-400">Blog</Link>
          <Link to="/profile" className="text-white hover:text-gray-400">Profile</Link>
          <LoginButton></LoginButton>
          <LogoutButton></LogoutButton>
          <button id="installButton" onClick={installApp}>DL Local App</button>
          
        </nav>
        <div className="md:hidden" onClick={toggleMenu}>
          <span className="block w-8 h-0.5 bg-white mb-1"></span>
          <span className="block w-8 h-0.5 bg-white mb-1"></span>
          <span className="block w-8 h-0.5 bg-white"></span>
        </div>
      </div>
    </header>
  );
};

export default Header;