import React, { useState, useEffect } from 'react';
import gameSettings from '../../game-settings';
import petNames from '../../assets/pet-names.json';
import {generateRandomName, generateRandomColour} from '../../utils/utils'

const PetForm = ({ onCreate }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState(gameSettings.petTypes[0]);
  const [color, setColor] = useState('#000000');

  useEffect(() => {
    generateRandomName(petNames, setName);
    generateRandomColour(setColor);
  }, []);  

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate({ name, type, color, age: 0 });
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded">
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
          Pet Name
        </label>
        <div className="flex">
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
          <button
            type="button"
            onClick={generateRandomName}
            className="bg-blue-500 text-white px-4 py-2 rounded ml-2 hover:bg-blue-600"
          >
            Randomize
          </button>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="type">
          Pet Type
        </label>
        <select
          id="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          className="w-full p-2 border rounded"
        >
          {gameSettings.petTypes.map((petType) => (
            <option key={petType} value={petType}>
              {petType}
            </option>
          ))}
        </select>
      </div>
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Create Pet
      </button>
    </form>
  );
};

export default PetForm;