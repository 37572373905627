import React from 'react';

const Navbar = ({ sections, activeSection, setActiveSection }) => {
  return (
    <div className="flex justify-stretch bg-gray-200">
      {sections.map((section) => (
        <button
          key={section.name}
          className={`px-4 py-2 ${activeSection === section.name ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          onClick={() => setActiveSection(section.name)}
        >
          {section.label} <span className="bg-white text-blue-500 rounded-full px-2 py-1 ml-2">{section.count}</span>
        </button>
      ))}
    </div>
  );
};

export default Navbar;
