import React, { useState, useEffect, useRef } from 'react';
import CompleteTaskModal from './complete-task-modal';
import MathQuestionTaskModal from './math-question-task-modal';
import PoopScoopTaskModal from './poop-scoop-task-modal';
import { v4 as uuidv4 } from 'uuid';
import { TaskTypes, TaskTypeToString } from '../../utils/task-types';
import { logEvent } from '../../utils/utils';
import { useToast } from '../../ToastContext'; // Import the useToast hook

const TaskArea = ({ pets, setHistory, setActiveTasks }) => {
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);

  const petRefs = useRef(pets); // Refs for pets to track their state
  const addToast = useToast();

  useEffect(() => {
    petRefs.current = pets;
  }, [pets]);

  useEffect(() => {
    const generateTaskProbability = (numPets) => {
      switch (numPets) {
        case 0: return 0;
        case 1: return 0.05;
        case 2: return 0.1;
        case 3: return 0.15;
        case 4: return 0.2;
        case 5: return 0.25;
        default: return 0.3;
      }
    };

    const createTask = () => {
      if (Math.random() < generateTaskProbability(petRefs.current.length)) {
        const taskTypes = [TaskTypes.POOP_SCOOP, TaskTypes.COMPLETE];
        const taskType = taskTypes[Math.floor(Math.random() * taskTypes.length)];
        const newTask = { id: uuidv4(), title: TaskTypeToString(taskType), type: taskType };

        setTasks(prevTasks => {
          const newTasks = [...prevTasks, newTask];
          localStorage.setItem('tasks', JSON.stringify(newTasks));
          logEvent(`Task created: ${newTask.title}`, setHistory); // Log task creation
          setActiveTasks(newTasks);
          addToast(`New Task Given: ${newTask.title}`, 'bg-gray-800');
          return newTasks;
        });
      }
    };

    const interval = setInterval(createTask, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleCompleteTask = (taskId) => {
    setTasks(prevTasks => {
      const completedTask = prevTasks.find(task => task.id === taskId);
      const updatedTasks = prevTasks.filter(task => task.id !== taskId);
      localStorage.setItem('tasks', JSON.stringify(updatedTasks));
      logEvent(`Task completed: ${completedTask.title}`, setHistory); // Log task completion
      setActiveTasks(updatedTasks);
      return updatedTasks;
    });
    setSelectedTask(null);
  };

  return (
    <div className="task-area mb-4">
      {isExpanded && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-0 p-4 bg-gray-600 rounded-b-md">
          {tasks.map(task => (
            <div
              key={task.id}
              className="task-item bg-gray-200 p-4 rounded shadow hover:bg-gray-300 cursor-pointer"
              onClick={() => setSelectedTask(task)}
            >
              {task.title}
            </div>
          ))}
        </div>
      )}
      {selectedTask && selectedTask.type === TaskTypes.COMPLETE && (
        <CompleteTaskModal
          task={selectedTask}
          onComplete={() => handleCompleteTask(selectedTask.id)}
          onClose={() => setSelectedTask(null)}
        />
      )}
      {selectedTask && selectedTask.type === TaskTypes.MATH_QUESTION && (
        <MathQuestionTaskModal
          task={selectedTask}
          onComplete={() => handleCompleteTask(selectedTask.id)}
          onClose={() => setSelectedTask(null)}
        />
      )}
      {selectedTask && selectedTask.type === TaskTypes.POOP_SCOOP && (
        <PoopScoopTaskModal
          task={selectedTask}
          onComplete={() => handleCompleteTask(selectedTask.id)}
          onClose={() => setSelectedTask(null)}
          numberOfPets={pets.length}
        />
      )}
    </div>
  );
};

export default TaskArea;
