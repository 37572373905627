import React, { useEffect, useRef, useState } from 'react';

const PoopScoopTaskModal = ({ task, onComplete, onClose, numberOfPets }) => {
  const canvasRef = useRef(null);

  const getRandomPosition = (maxWidth, maxHeight, elementWidth, elementHeight) => {
    const x = Math.floor(Math.random() * (maxWidth - elementWidth));
    const y = Math.floor(Math.random() * (maxHeight - elementHeight));
    return { x, y };
  };

  const numberOfPoops = Math.min(Math.max(numberOfPets, 1), 6);

  const [poops, setPoops] = useState(() => {
    const poopWidth = 30;
    const poopHeight = 30;
    const positions = [];
    for (let i = 0; i < numberOfPoops; i++) {
      const pos = getRandomPosition(400, 400, poopWidth, poopHeight);
      positions.push({ id: i + 1, x: pos.x, y: pos.y, width: poopWidth, height: poopHeight });
    }
    return positions;
  });

  const [draggingPoop, setDraggingPoop] = useState(null);

  const [bin] = useState(() => {
    const binWidth = 50;
    const binHeight = 50;
    return getRandomPosition(400, 400, binWidth, binHeight);
  });
  const [poopImage, setPoopImage] = useState(null);
  const [binImage, setBinImage] = useState(null);

  const poopSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30">
      <path fill="#6B4F1E" d="M16 1c-1.104 0-2 .896-2 2 0 .432.144.816.375 1.125-.19-.043-.371-.125-.625-.125-1.104 0-2 .896-2 2 0 .337.088.635.219.938-.136-.026-.28-.063-.438-.063-1.104 0-2 .896-2 2 0 .297.08.575.188.844C6.674 12.176 4 15.341 4 19c0 4.418 3.582 8 8 8h8c4.418 0 8-3.582 8-8 0-4.126-2.816-7.5-6.594-8.469-.289-.072-.572-.11-.844-.156.01-.124.063-.231.063-.375 0-1.104-.896-2-2-2-.238 0-.452.05-.656.125-.128-.303-.219-.601-.219-.938 0-1.104-.896-2-2-2-.363 0-.674.116-1 .25V3c0-1.104-.896-2-2-2zm-2 13c.553 0 1 .447 1 1s-.447 1-1 1-1-.447-1-1 .447-1 1-1zm4 1c0-.553.447-1 1-1s1 .447 1 1-.447 1-1 1-1-.447-1-1zm-4.094 3c-.23 0-.45.064-.625.156-.227.113-.42.273-.594.469-.347.391-.633.97-.938 1.562-.304.592-.59 1.186-.875 1.563H19.78c-.285-.377-.57-.971-.875-1.563-.305-.592-.591-1.171-.938-1.562-.347-.391-.742-.625-1.281-.625z" />
    </svg>
  `;

  const binSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50" height="50">
      <path fill="#7B8A8B" d="M21 11V8c0-2.8 2.2-5 5-5h12c2.8 0 5 2.2 5 5v3h11v4H10v-4h11zm-7 6h36l-4 42c0 2.8-2.2 5-5 5H23c-2.8 0-5-2.2-5-5l-4-42z"/>
    </svg>
  `;

  useEffect(() => {
    const loadImage = (svg, setImage) => {
      const img = new Image();
      const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      img.onload = () => {
        setImage(img);
        URL.revokeObjectURL(url);
      };
      img.src = url;
    };

    loadImage(poopSvg, setPoopImage);
    loadImage(binSvg, setBinImage);
  }, []);

  const handleMouseMove = (e) => {
    const x = e.clientX || (e.touches && e.touches[0].clientX);
    const y = e.clientY || (e.touches && e.touches[0].clientY);

    if (draggingPoop && x != null && y != null) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const newX = x - rect.left;
      const newY = y - rect.top;

      // Update the position of the dragging poop
      setPoops((prevPoops) =>
        prevPoops.map((poop) =>
          poop.id === draggingPoop.id ? { ...poop, x: newX, y: newY } : poop
        )
      );

      // Check for collision with the bin
      const poopRect = { ...draggingPoop, x: newX, y: newY };

      if (
        poopRect.x >= bin.x &&
        poopRect.x <= bin.x + binImage.width &&
        poopRect.y >= bin.y &&
        poopRect.y <= bin.y + binImage.height
      ) {
        handleDrop(draggingPoop.id);
        setDraggingPoop(null); // Stop dragging after dropping into the bin
      }
    }
  };

  const handleMouseUp = () => {
    setDraggingPoop(null);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('touchmove', handleMouseMove);
    document.removeEventListener('touchend', handleMouseUp);
  };

  useEffect(() => {
    const canvas = canvasRef.current;

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('touchstart', handleMouseDown);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('touchstart', handleMouseDown);
    };
  }, [draggingPoop]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const drawPoop = (poop) => {
      if (poopImage) {
        ctx.drawImage(poopImage, poop.x - poop.width / 2, poop.y - poop.height / 2, poop.width, poop.height);
      }
    };

    const drawBin = () => {
      if (binImage) {
        ctx.drawImage(binImage, bin.x, bin.y);
      }
    };

    const render = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      poops.forEach(drawPoop);
      drawBin();
    };

    render();
  }, [poops, binImage, poopImage, bin.x, bin.y]);

  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent scrolling on touch devices
    const x = e.clientX || (e.touches && e.touches[0].clientX);
    const y = e.clientY || (e.touches && e.touches[0].clientY);

    if (x != null && y != null) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const newX = x - rect.left;
      const newY = y - rect.top;

      const selectedPoop = poops.find(
        (poop) =>
          newX >= poop.x - poop.width / 2 &&
          newX <= poop.x + poop.width / 2 &&
          newY >= poop.y - poop.height / 2 &&
          newY <= poop.y + poop.height / 2
      );

      if (selectedPoop) {
        setDraggingPoop(selectedPoop);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('touchend', handleMouseUp);
      }
    }
  };

  const handleDrop = (id) => {
    setPoops((prevPoops) => {
      const remainingPoops = prevPoops.filter((poop) => poop.id !== id);
      if (remainingPoops.length === 0) {
        handleMouseUp();
        onComplete();        
      }
      return remainingPoops;
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-full max-w-md relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Clean the Poop</h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900">&times;</button>
        </div>
        <div className="mb-4">Drag all the poops into the bin to complete the task.</div>
        <canvas
          ref={canvasRef}
          width={400}
          height={400}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
          className="border"
        />
      </div>
    </div>
  );
};

export default PoopScoopTaskModal;
