import React, { useState, useEffect, useCallback, useRef } from "react";
import BreedingModal from "../breeding-modal/breeding-modal";
import { logEvent, getPercentage, generateRandomName } from "../../utils/utils";
import { getContrastingTextColor } from "../../utils/getContrastingTextColor";
import {
  CalculateBreedAttemptincrease,
  CalculateBreedSuccessRate,
} from "../../utils/pet-data";
import gameSettings from "../../game-settings";
import petNames from "../../assets/pet-names.json";
import "./breeding.css";

const Breeding = ({
  pets,
  setPets,
  setHistory,
  breedPets,
  breedingPens,
  setBreedingPens,
}) => {
  const [isBreedingModalOpen, setIsBreedingModalOpen] = useState(false);
  const [currentPenId, setCurrentPenId] = useState(null);
  const [tempSelectedPets, setTempSelectedPets] = useState([]); // Temporary state for modal selection
  const [animationKey, setAnimationKey] = useState(Date.now()); // Key to force re-render

  const penRefs = useRef(breedingPens); // Refs for each pen to track their state
  const petRefs = useRef(pets); // Refs for pets to track their state
  const lastAttemptTimeRef = useRef(Date.now());

  const calculateSuccessRate = useCallback((pet1, pet2) => {
    const baseRate = (pet1.breedSuccessRate + pet2.breedSuccessRate) / 2;
    return baseRate * calculateCompatibilityMultiplier(pet1, pet2);
  }, []);

  const calculateCompatibilityMultiplier = (pet1, pet2) => {
    const sizeDifference = Math.abs(pet1.size - pet2.size);
    var result = 1.2 - 0.1 * sizeDifference;
    return parseFloat(result.toFixed(2));
  };

  const doesPetHaveChild = (parentPet) => {
    return petRefs.current.some(
      (pet) =>
        pet.parentIds &&
        pet.parentIds.some((id) => id === parentPet.id) &&
        pet.age <= gameSettings.ageGroups.Teen.min
    );
  };

  const canBreed = (pet1, pet2, pen) => {
    let canBreed = true;
    const cantBreedReasons = [];
    if (doesPetHaveChild(pet1)) {
      canBreed = false;
      cantBreedReasons.push(`${pet1.name} has a child`);
    }
    if (doesPetHaveChild(pet2)) {
      canBreed = false;
      cantBreedReasons.push(`${pet2.name} has a child`);
    }

    setBreedingPens((prevPens) =>
      prevPens.map((p) => (p.id === pen.id ? { ...p, cantBreedReasons } : p))
    );
    return canBreed;
  };

  useEffect(() => {
    const increaseBreedSuccessRate = (pet1) => {
      setPets((prevPets) =>
        prevPets.map((pet) => {
          if (pet.id === pet1.id) {
            const averageIncrease = CalculateBreedAttemptincrease([
              ...pet1.baseTypes,
            ]);
            return {
              ...pet,
              breedSuccessRate: pet.breedSuccessRate + averageIncrease,
            };
          }
          return pet;
        })
      );
    };

    const resetBreedSuccessRate = (pet1) => {
      setPets((prevPets) =>
        prevPets.map((pet) => {
          if (pet.id === pet1.id) {
            return {
              ...pet,
              breedSuccessRate: CalculateBreedSuccessRate(pet1.baseTypes),
            };
          }
          return pet;
        })
      );
    };

    const attemptBreeding = (pen) => {
      const penRef = penRefs.current[pen.id - 1];
      const selectedPets = penRef?.selectedPets || [];
      if (selectedPets.length === 2) {
        const [pet1, pet2] = selectedPets;

        if (!canBreed(pet1, pet2, pen)) {
          return;
        }

        if (pet1 && pet2) {
          const successRate = calculateSuccessRate(pet1, pet2);

          if (Math.random() <= successRate) {
            // Successful breeding
            breedPets(pet1, pet2, penRef.babyName);
            resetBreedSuccessRate(pet1);
            resetBreedSuccessRate(pet2);
            logEvent(
              `Breeding successful: ${pet1.name} and ${pet2.name}.`,
              setHistory
            );
            setBreedingPens((prevPens) => {
              return prevPens.map((p) => {
                if (!p.selectedPets) {
                  return p;
                }

                const newCantBreeds = [];
                if (p.selectedPets.find(parent => parent.id === pet1.id)) {
                  newCantBreeds.push(`${pet1.name} has a child`);
                }
                if (p.selectedPets.find(parent => parent.id === pet2.id)) {
                  newCantBreeds.push(`${pet2.name} has a child`);
                }
                return {
                  ...p,
                  babyName:
                    p.id === pen.id
                      ? petNames[Math.floor(Math.random() * petNames.length)]
                      : p.babyName,
                  cantBreedReasons: p.cantBreedReasons && p.cantBreedReasons.length > 0 ? p.cantBreedReasons.concat(newCantBreeds) : newCantBreeds,
                };
              });
            });
          } else {
            // Breeding failed, increase breedSuccessRate
            increaseBreedSuccessRate(pet1);
            increaseBreedSuccessRate(pet2);
            logEvent(
              `Breeding failed: ${pet1.name} and ${pet2.name}. Increased breeding success rate.`,
              setHistory
            );
          }
        }
      }
    };

    penRefs.current = breedingPens.map((pen) => ({
      ...pen,
      selectedPets: pen.selectedPets,
    }));

    const intervals = breedingPens.map((pen) =>
      setInterval(() => {
        attemptBreeding(pen);
        lastAttemptTimeRef.current = Date.now(); // Reset last attempt time
        setAnimationKey(Date.now()); // Change key to trigger re-render
      }, gameSettings.ageInterval)
    );

    return () => intervals.forEach(clearInterval);
  }, [breedingPens.length]);

  useEffect(() => {
    // Update penRefs and filter out dead or non-existing pets
    if (pets.length === 0 && petRefs.current && petRefs.current.length === 0)
      return;

    setBreedingPens((prevPens) =>
      prevPens.map((pen) => ({
        ...pen,
        selectedPets: pen.selectedPets
          .map(
            (selectedPet) =>
              petRefs.current.find((pet) => pet.id === selectedPet.id) || null
          )
          .filter(Boolean), // Filter out null values
      }))
    );
    penRefs.current = breedingPens.map((pen) => ({
      ...pen,
      selectedPets: pen.selectedPets,
    }));
    petRefs.current = pets;
  }, [pets]);

  const handleConfirmSelection = (penId, selectedParents, babyName) => {
    setBreedingPens((prevPens) =>
      prevPens.map((pen) =>
        pen.id === penId
          ? { ...pen, selectedPets: selectedParents, babyName }
          : pen
      )
    );
    penRefs.current = breedingPens.map((pen) =>
      pen.id === penId ? { ...pen, selectedPets: selectedParents } : pen
    );
    logEvent(
      `Started breeding attempt between ${selectedParents[0].name} and ${selectedParents[1].name}.`,
      setHistory
    );
    let pen = penRefs.current.find((pen) => pen.id === penId);
    const [pet1, pet2] = selectedParents;
    canBreed(pet1, pet2, pen);
    setIsBreedingModalOpen(false);
  };

  // Use memoization to ensure the calculation is only performed when necessary
  const calculateElapsedPercentage = useCallback(
    (pen) => {
      const selectedPets = penRefs.current[pen.id - 1]?.selectedPets || [];

      if (selectedPets.length < 2 || pen.cantBreedReasons.length > 0) {
        return 100; // No animation if not breeding
      }
      const now = Date.now();
      const elapsed = now - lastAttemptTimeRef.current;
      const percentage = Math.min(
        (elapsed / gameSettings.ageInterval) * 100,
        100
      );
      return percentage;
    },
    [animationKey]
  ); // Depend on animationKey to ensure it updates correctly

  return (
    <div className="p-4 bg-gray-200 rounded">
      <h2 className="text-xl font-bold mb-4">Breeding Pens</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2">
        {breedingPens.map((pen) => (
          <div
            key={pen.id}
            className="breeding-pen mb-4 p-4 bg-white rounded shadow"
          >
            <h3 className="text-lg font-bold mb-2">Breeding Pen {pen.id}</h3>
            <button
              onClick={() => {
                setTempSelectedPets(pen.selectedPets);
                setCurrentPenId(pen.id);
                setIsBreedingModalOpen(true);
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {pen.selectedPets.length < 2
                ? "Select Pets in Pen"
                : "Change Pets in Pen"}
            </button>
            {pen.selectedPets.length === 2 && (
              <div>
                <div className="selected-pets">
                  {pen.selectedPets.map((pet) => (
                    <div
                      key={pet.id}
                      className="p-2 mt-2 border border-gray-400 rounded"
                      style={{
                        backgroundColor: pet.color,
                        color: getContrastingTextColor(pet.color),
                      }}
                    >
                      {pet.name} ({pet.type}) Pets success rate:{" "}
                      {getPercentage(pet.breedSuccessRate)}
                    </div>
                  ))}
                </div>
                <div
                  className="relative p-2 m-2 border border-blue-500 rounded"
                  key={animationKey} // Using animationKey to reset animation
                >
                  <div
                    className="absolute top-0 left-0 h-full bg-blue-400 z-0"
                    style={{
                      width: "100%",
                      "--intervalPercentage": `${calculateElapsedPercentage(
                        pen
                      )}%`,
                      animation: `fillBar ${gameSettings.ageInterval}ms linear infinite`,
                      zIndex: 0,
                    }}
                  ></div>
                  <span className="relative z-10">
                    Baby to be called:{" "}
                    <span className="font-bold">{pen.babyName}</span>
                  </span>
                </div>
                <div className="p-2 m-2">
                  <p className="text-sm">
                    Compatibility modifier:{" "}
                    {calculateCompatibilityMultiplier(
                      pen.selectedPets[0],
                      pen.selectedPets[1]
                    )}
                  </p>
                  <p className="text-sm">
                    Final success rate:{" "}
                    {getPercentage(
                      calculateSuccessRate(
                        pen.selectedPets[0],
                        pen.selectedPets[1]
                      )
                    )}
                  </p>
                  {pen.cantBreedReasons.length > 0 && (
                    <>
                      <h2 className="text-md font-bold mb-2">
                        Breeding paused:
                      </h2>
                      {pen.cantBreedReasons.map((reason, index) => (
                        <p className="text-sm" key={index}>
                          {reason}
                        </p>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {isBreedingModalOpen && (
        <BreedingModal
          pets={pets}
          tempSelectedPets={tempSelectedPets}
          setTempSelectedPets={setTempSelectedPets}
          onConfirm={(selectedParents, babyName) =>
            handleConfirmSelection(currentPenId, selectedParents, babyName)
          }
          onClose={() => setIsBreedingModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Breeding;
