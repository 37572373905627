import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import matter from "gray-matter";
import { markdownComponents } from "../../utils/utils"
import "./blog-post.css";
window.Buffer = window.Buffer || require("buffer").Buffer;

const BlogPost = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [meta, setMeta] = useState({ title: "", date: "" });

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const context = require.context("../../articles", false, /\.md$/);
        const articles = context.keys().map((key) => ({
          slug: key.match(/\.\/(.*)\.md$/)[1],
          path: context(key),
        }));

        const parsedPosts = await Promise.all(
          articles.map(async (article) => {
            const response = await fetch(article.path);
            const text = await response.text();
            const { data, content } = matter(text);
            return {
              title: data.title,
              date: new Date(data.date),
              content,
              slug: article.slug,
            };
          })
        );

        // Sort posts by date (ascending)
        parsedPosts.sort((a, b) => a.date - b.date);

        // Assign incremented IDs based on sorted order
        parsedPosts.forEach((post, index) => {
          post.id = index + 1;
        });

        const post = parsedPosts.find((post) => post.id === parseInt(id, 10));

        if (post) {
          setMeta({ title: post.title, date: post.date.toLocaleDateString() });
          setContent(post.content);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchPost();
  }, [id]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-5xl font-bold mb-2">{meta.title}</h1>
      <p className="text-gray-600 mb-4">{meta.date}</p>
      <ReactMarkdown components={markdownComponents}>{content}</ReactMarkdown>
    </div>
  );
};

export default BlogPost;
