import React, { useState, useEffect, useRef } from "react";
import { getContrastingTextColor } from "../../utils/getContrastingTextColor";
import { CatSvg, FishSvg, SheepSvg, DogSvg } from "../../utils/pet-svgs";
import { mergeAnimalSvgs } from "../../utils/pet-svg-merge";
import gameSettings from "../../game-settings";
import { useToast } from '../../ToastContext';
import "./pet.css";

const Pet = ({ pet, onFeed, onPlay, onSleep, onWakeUp, onRemove, speed }) => {
  const [isFeeding, setIsFeeding] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [feedProgress, setFeedProgress] = useState(100);
  const [playProgress, setPlayProgress] = useState(100);
  const [lastFeedTimestamp, setLastFeedTimestamp] = useState(() => {
    const savedTimestamp = localStorage.getItem(`lastFeed_${pet.id}`);
    return savedTimestamp ? parseInt(savedTimestamp, 10) : 0;
  });
  const [lastPlayTimestamp, setLastPlayTimestamp] = useState(() => {
    const savedTimestamp = localStorage.getItem(`lastPlay_${pet.id}`);
    return savedTimestamp ? parseInt(savedTimestamp, 10) : 0;
  });
  const [behaviour, setBehaviour] = useState("");

  const addToast = useToast();
  const feedTimeoutRef = useRef(null);
  const playTimeoutRef = useRef(null);

  const behaviours = ["jump", "shake", "spin"];

  const getRandomBehaviour = () =>
    behaviours[Math.floor(Math.random() * behaviours.length)];

  const applyBehaviour = () => {
    if (behaviour !== "") return;
    const newBehaviour = getRandomBehaviour();
    setBehaviour(newBehaviour);
    setTimeout(() => setBehaviour(""), 1000); // Reset behaviour after 1 second
  };

  const updateTimer = (
    lastTimestamp,
    setIsActive,
    setProgress,
    timeoutRef,
    cooldownPeriod
  ) => {
    const now = Date.now();
    const elapsed = now - lastTimestamp;
    const totalCooldown = cooldownPeriod;
    const remainingTime = totalCooldown - elapsed * gameSettings.speeds["Fast"]; // Fixed to use 'Fast'

    if (remainingTime <= 0) {
      setIsActive(false);
      setProgress(100);
      clearTimeout(timeoutRef.current);
    } else {
      const newRemainingTime = remainingTime / gameSettings.speeds[speed];
      setIsActive(true);
      setProgress((elapsed / totalCooldown) * 100);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsActive(false);
        setProgress(100);
      }, newRemainingTime);

      setTimeout(() => {
        setProgress(100);
      }, newRemainingTime);
    }
  };

  useEffect(() => {
    updateTimer(
      lastFeedTimestamp,
      setIsFeeding,
      setFeedProgress,
      feedTimeoutRef,
      gameSettings.cooldownPeriods.feed
    );
    updateTimer(
      lastPlayTimestamp,
      setIsPlaying,
      setPlayProgress,
      playTimeoutRef,
      gameSettings.cooldownPeriods.play
    );

    return () => {
      clearTimeout(feedTimeoutRef.current);
      clearTimeout(playTimeoutRef.current);
    };
  }, [lastFeedTimestamp, lastPlayTimestamp, speed]);

  useEffect(() => {
    let sleepInterval;
    if (pet.isSleeping) {
      sleepInterval = setInterval(() => {
        onSleep(pet.id);
      }, 1000);
    }
    return () => clearInterval(sleepInterval);
  }, [pet.isSleeping, onSleep, pet.id]);

  const handleFeed = () => {
    if (isFeeding || pet.isSleeping || pet.isDead) return;

    onFeed(pet.id);
    setIsFeeding(true);
    setFeedProgress(100);
    applyBehaviour();
    const now = Date.now();
    setLastFeedTimestamp(now);
    localStorage.setItem(`lastFeed_${pet.id}`, now);
    clearTimeout(feedTimeoutRef.current);
    feedTimeoutRef.current = setTimeout(() => {
      setIsFeeding(false);
      setFeedProgress(0);
    }, gameSettings.cooldownPeriods.feed / gameSettings.speeds[speed]);
  };

  const handlePlay = () => {
    if (isPlaying || pet.isSleeping || pet.isDead) return;

    onPlay(pet.id);
    setIsPlaying(true);
    setPlayProgress(100);
    applyBehaviour();
    const now = Date.now();
    setLastPlayTimestamp(now);
    localStorage.setItem(`lastPlay_${pet.id}`, now);
    clearTimeout(playTimeoutRef.current);
    playTimeoutRef.current = setTimeout(() => {
      setIsPlaying(false);
      setPlayProgress(0);
    }, gameSettings.cooldownPeriods.play / gameSettings.speeds[speed]);
  };

  const handleSleep = () => {
    if (pet.isDead) return;
    onSleep(pet.id);
  };

  const handleWakeUp = () => {
    if (pet.isDead) return;
    onWakeUp(pet.id);
  };

  const handleTransitionEnd = (e) => {
    if (e.propertyName === "opacity" && pet.isDead) {
      onRemove(pet.id);
    }
  };

  const renderPetSvg = (type) => {
    switch (type) {
      case "Cat":
        return <CatSvg />;
      case "Fish":
        return <FishSvg />;
      case "Sheep":
        return <SheepSvg />;
      case "Dog":
        return <DogSvg />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`p-3 rounded-3xl shadow-md transition-opacity duration-300 ${
        pet.isDead ? "opacity-0" : "opacity-100"
      } flex flex-col justify-between`}
      style={{
        backgroundColor: pet.color,
        color: getContrastingTextColor(pet.color),
      }}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="text-lg font-bold">
        {pet.name} ({pet.type})
      </div>
      <div className="text-sm mb-2">
        Age: {pet.age} ({pet.ageGroup})
      </div>
      <div>
        <div className={`flex justify-center ${behaviour}`}>
          {pet.baseTypes?.length > 1
            ? mergeAnimalSvgs(pet.baseTypes)
            : renderPetSvg(pet.type)}
        </div>
      </div>
      <div className="inline">
        <div className="mb-2">Hunger:</div>
        <div className="relative w-full bg-gray-300 rounded h-2 sm:h-6 overflow-hidden">
          <div
            className="bg-red-500 h-full transition-width duration-500 ease-in-out"
            style={{ width: `${pet.hunger}%` }}
          ></div>
        </div>
      </div>
      <div className="mb-2">Happiness:</div>
      <div className="relative w-full bg-gray-300 rounded h-2 sm:h-6 overflow-hidden">
        <div
          className="bg-yellow-500 h-full transition-width duration-500 ease-in-out"
          style={{ width: `${pet.happiness}%` }}
        ></div>
      </div>
      <div className="mb-2">Energy:</div>
      <div className="relative w-full bg-gray-300 rounded h-2 sm:h-6 overflow-hidden">
        <div
          className="bg-green-500 h-full transition-width duration-500 ease-in-out"
          style={{ width: `${pet.energy}%` }}
        ></div>
      </div>
      {pet.isDead && <div className="text-4xl">DEAD</div>}
      <div className="flex justify-between mt-2">
        <button
          onClick={handleFeed}
          disabled={isFeeding}
          className={`m-0.5 relative overflow-hidden px-1 py-1 lg:px-3 lg:py-2 rounded text-sm bg-blue-500 hover:bg-blue-600 text-white`}
        >
          <div
            className={`absolute top-0 left-0 h-full ${
              isFeeding ? "bg-gray-500" : ""
            }`}
            style={
              isFeeding
                ? {
                    width: `${feedProgress}%`,
                    transition: `width ${
                      gameSettings.cooldownPeriods.feed /
                      gameSettings.speeds[speed]
                    }ms linear`,
                  }
                : {}
            }
          ></div>
          <span className="relative z-10 text-xs sm:text-sm">
            {isFeeding || pet.isSleeping ? "🚫 Feed" : "🍖 Feed"}
          </span>
        </button>
        <button
          onClick={handlePlay}
          disabled={isPlaying}
          className={`m-0.5 relative overflow-hidden px-1 py-1 lg:px-3 lg:py-2 rounded text-sm bg-blue-500 hover:bg-blue-600 text-white`}
        >
          <div
            className={`absolute top-0 left-0 h-full ${
              isPlaying ? "bg-gray-500" : ""
            }`}
            style={
              isPlaying
                ? {
                    width: `${playProgress}%`,
                    transition: `width ${
                      gameSettings.cooldownPeriods.play /
                      gameSettings.speeds[speed]
                    }ms linear`,
                  }
                : {}
            }
          ></div>
          <span className="relative z-10 text-xs sm:text-sm">
            {isPlaying || pet.isSleeping ? "🚫 Play" : "⚽ Play"}
          </span>
        </button>
        <button
          onClick={pet.isSleeping ? handleWakeUp : handleSleep}
          className={`m-0.5 px-1 py-1 lg:px-3 lg:py-2 rounded text-sm bg-blue-500 hover:bg-blue-600 text-white`}
        >
          <span className="relative z-10 text-xs sm:text-sm">
            {pet.isSleeping ? "⏰ Wake" : "💤 Sleep"}
            </span>
        </button>
      </div>
    </div>
  );
};

export default Pet;
