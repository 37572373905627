import React, { useState } from 'react';

const MathQuestionTaskModal = ({ task, onComplete, onClose }) => {
  const [answer, setAnswer] = useState('');
  const [feedback, setFeedback] = useState('');
  const [question] = useState(generateMathQuestion());

  function generateMathQuestion() {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    return { num1, num2, answer: num1 + num2 };
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(answer) === question.answer) {
      onComplete();
    } else {
      setFeedback('Incorrect answer, please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Solve the Math Problem</h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900">&times;</button>
        </div>
        <form onSubmit={handleSubmit}>
          <p className="mb-4">{`What is ${question.num1} + ${question.num2}?`}</p>
          <input
            type="number"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            className="w-full p-2 border rounded mb-4"
            required
          />
          {feedback && <p className="text-red-500 mb-4">{feedback}</p>}
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default MathQuestionTaskModal;
