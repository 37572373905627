function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    return [r, g, b];
  }
  
  function getLuminance([r, g, b]) {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }
  
  export function getContrastingTextColor(hex) {
    const rgb = hexToRgb(hex);
    const luminance = getLuminance(rgb);
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }