import React from 'react';
import PetForm from './pet-form';

const PetFormModal = ({ onCreate, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Create Pet</h2>
          <button onClick={onClose} className="text-xl font-bold text-gray-400 hover:text-gray-900">X</button>
        </div>
        <PetForm onCreate={onCreate} />
      </div>
    </div>
  );
};

export default PetFormModal;
