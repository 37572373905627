import React from 'react';
import './about.css';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const About = () => {
  return (
    <div className="container mx-auto p-4">
      <div className='text-center'><Logo  className="inline h-80 w-80" /></div>
      <p>
        Welcome to Pet App! Our app allows you to create and manage virtual pets, each with their own unique attributes and needs.
      </p>
      <p>
        This app was created by a team of passionate developers who love pets and wanted to create a fun and interactive experience for users.
      </p>
    </div>
  );
};

export default About;