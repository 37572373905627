import React from "react";
import { ANIMAL_ANCHOR_POINTS, BodyParts } from "./pet-svgs";

export const mergeAnimalSvgs = (baseTypes) => {
  var baseTypesCounter = 0;
  const gElements = BodyParts.map((bodyPart, index) => {
    const layerType = ANIMAL_ANCHOR_POINTS[baseTypes[baseTypesCounter]];
    const baseLayer = ANIMAL_ANCHOR_POINTS[baseTypes[0]];

    if (baseTypesCounter >= baseTypes.length-1) {
        baseTypesCounter = 0;
      } else {
        baseTypesCounter++;
      }

    if (layerType && layerType[bodyPart]) {
      return (
        <g
          key={index}
          transform={`scale(${layerType[bodyPart].scale}) translate(${layerType[bodyPart].x}, ${layerType[bodyPart].y})`}
        >
          {layerType[bodyPart].svg}
        </g>
      );
    }
    return null;
  }).filter(Boolean); // filter out null values

  return (
    <svg width="110" height="110" xmlns="http://www.w3.org/2000/svg">
      {gElements}
    </svg>
  );
};
