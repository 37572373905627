const gameSettings = {
  speeds: {
    Fast: 5,
    Normal: 1,
    Slow: 0.5,
  },
  depletionRates: {
    hunger: 0.5,
    happiness: 0.5,
    energy: 0.5,
    energyIncrease: 2,
  },
  increments: {
    feed: 20,
    play: {
      happiness: 20,
      energy: -15,
    },
  },
  cooldownPeriods: {
    feed: 15000,
    play: 15000,
  },
  ageInterval: 15000, // 15 seconds in normal speed
  ageGroups: {
    Baby: { min: 0, max: 2 },
    Child: { min: 2, max: 13 },
    Teen: { min: 13, max: 20 },
    Adult: { min: 20, max: 80 },
    Elder: { min: 80, max: Infinity },
  },
  petCreationBasePrice: 10,
  petTypes: ["Dog", "Cat", "Fish", "Sheep"]
};

export default gameSettings;