import React, { useState } from "react";
const PetHistory = ({ history }) => {
  const [isPetHistoryOpen, setIsPetHistoryOpen] = useState(false);

  const togglePetHistory = () => {
    setIsPetHistoryOpen((prev) => !prev);
  };

  return (
    <div className="pet-history bg-gray-100 rounded shadow-md">
      <div
        className="flex justify-stretch bg-gray-100 px-4 py-2 hover:bg-gray-200 rounded"
        onClick={togglePetHistory}
      >
        <h2 className="text-2xl font-bold">
          Pet History {isPetHistoryOpen ? "▲" : "▼"}
        </h2>
      </div>
      {isPetHistoryOpen && (
      <ul className="space-y-2 max-h-64 overflow-y-auto">
        {history.map((entry, index) => (
          <li key={index} className="bg-white p-3 rounded shadow">
            <strong className="text-blue-500">{entry.time}</strong>:{" "}
            {entry.message}
          </li>
        ))}
      </ul>
    )}
    </div>
  );
};

export default PetHistory;
