import React, { useState, useEffect, useRef } from "react";
import PetFormModal from "../pet-form/pet-form-modal";
import Pet from "../pet/pet";
import PetHistory from "../pet-history/pet-history";
import TaskArea from "../task-area/task-area";
import Shop from "../shop/shop";
import Navbar from "../navbar/navbar";
import gameSettings from "../../game-settings";
import usePets from "../../hooks/use-pets";
import useLocalStorageWithDefault from "../../hooks/use-local-storage-with-default";
import { logEvent } from "../../utils/utils";
import { itemData, initialItemData } from "../../utils/shop-items";
import { TestArea } from "../test-area/test-area";
import Breeding from "../breeding/breeding";
import { useToast } from '../../ToastContext'; // Import the useToast hook

const CreatePet = () => {
  const addToast = useToast();

  const [history, setHistory] = useLocalStorageWithDefault("history", []);
  const [coins, setCoins] = useLocalStorageWithDefault("coins", 10);
  const [showForm, setShowForm] = useState(false);
  const [speed, setSpeed] = useLocalStorageWithDefault("speed", "Normal");
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const [activeTasks, setActiveTasks] = useState([]);
  const [itemShop, setItemshop] = useLocalStorageWithDefault(
    "itemShop",
    initialItemData()
  );
  const [sections, setSections] = useLocalStorageWithDefault("sections", [
    { name: "tasks", label: "Tasks", count: activeTasks.length },
    { name: "shop", label: "Shop", count: itemShop.length },
  ]);
  const [breedingPens, setBreedingPens] = useLocalStorageWithDefault(
    "breedingPens",
    []
  );
  const [activeSection, setActiveSection] = useState("tasks");

  const {
    pets,
    setPets,
    addPet,
    breedPets,
    handleFeedPet,
    handlePlayPet,
    handleSleepPet,
    handleWakeUpPet,
    handleRemovePet,
  } = usePets(setHistory, setCoins, speed, activeTasks);

  const createPetCost = gameSettings.petCreationBasePrice * (pets.length + 1);

  const addBreedingPen = () => {
    setBreedingPens((prevPens) => [
      ...prevPens,
      {
        id: prevPens.length + 1,
        selectedPets: [],
        cantBreedReasons: [],
        babyName: "",
      },
    ]);
  };

  useEffect(() => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.name === "shop") {
          const affordableItemsCount = itemShop.filter(
            (item) => item.cost <= coins
          ).length;
          return { ...section, count: affordableItemsCount };
        }
        return section;
      })
    );
  }, [coins, itemShop]);

  useEffect(() => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.name === "tasks") {
          const activeTasksCount = activeTasks.length;
          return { ...section, count: activeTasksCount };
        }
        return section;
      })
    );
  }, [activeTasks]);

  useEffect(() => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.name === "breeding") {
          const pensActive = breedingPens.filter(
            (pen) =>
              pen.selectedPets.length === 2 && pen.cantBreedReasons.length === 0
          ).length;
          return { ...section, count: `${pensActive}/${breedingPens.length}` };
        }
        return section;
      })
    );
  }, [breedingPens]);

  useEffect(() => {
    if (pets.length === 0) {
      setCoins(Math.max(coins, 10));
    }
  }, [pets]);

  const handleCreatePet = (newPet) => {
    const cost = gameSettings.petCreationBasePrice * (pets.length + 1);
    if (coins >= cost) {
      addPet(newPet);
      setCoins((prevCoins) => prevCoins - cost);
      logEvent(`Pet ${newPet.name} was born.`, setHistory);
      setShowForm(false);
      setSpeed("Normal");
      setInsufficientFunds(false);
    } else {
      setInsufficientFunds(true);
    }
  };

  const handleShowPetForm = () => {
    const cost = gameSettings.petCreationBasePrice * (pets.length + 1);
    if (coins >= cost) {
      setShowForm(true);
      setSpeed("Slow");
      setInsufficientFunds(false);
    } else {
      setInsufficientFunds(true);
    }
  };

  const resetData = () => {
    localStorage.clear();
    setPets([]);
    setCoins(10);
    setSections([
      { name: "tasks", label: "Tasks", count: 0 },
      { name: "shop", label: "Shop", count: 0 },
    ]);
    setItemshop(initialItemData());
    setHistory([]);
    setShowForm(false);
    setBreedingPens([]);
  };

  const handleScroll = () => {
    const nav = document.getElementById("general-game-nav");
    const sectionNav = document.getElementById("section-nav");
    const isNavFixed = window.scrollY > nav.offsetTop;

    if (isNavFixed) {
      nav.classList.add("fixed", "top-0", "left-0", "right-0", "z-50");
      if (window.innerWidth < 640 && nav.offsetHeight > 50) {
        sectionNav.classList.add(
          "fixed",
          "top-16",
          "left-0",
          "right-0",
          "z-50"
        );
      } else {
        sectionNav.classList.add(
          "fixed",
          "top-10",
          "left-0",
          "right-0",
          "z-50"
        );
      }
    } else {
      nav.classList.remove("fixed", "top-0", "left-0", "right-0", "z-50");
      sectionNav.classList.remove(
        "fixed",
        "top-16",
        "top-10",
        "left-0",
        "right-0",
        "z-50"
      );
    }
  };

  useEffect(() => {
    const nav = document.getElementById("general-game-nav");
    const observer = new ResizeObserver(() => {
      handleScroll();
    });

    observer.observe(nav);
    window.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      handleScroll();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, []);

  return (
    <div className="container mx-auto">
        <TestArea
          addPet={addPet}
          resetData={resetData}
          setHistory={setHistory}
          addBreedingPen={addBreedingPen}
        />
        {showForm && (
          <PetFormModal
            onCreate={handleCreatePet}
            onClose={() => {
              setShowForm(false);
              setSpeed("Normal");
            }}
          />
        )}
        <div id="general-game-nav" className="flex justify-stretch bg-gray-200">
          <div className="text-xl font-bold px-2 py-2">🪙 {coins}</div>
          {insufficientFunds && (
            <div className="text-red-500">
              Insufficient funds to create a new pet.
            </div>
          )}
          <button
            onClick={handleShowPetForm}
            className="bg-blue-500 text-white px-4 py-2 hover:bg-blue-600"
          >
            Buy Pet {createPetCost} 🪙
          </button>
          <label className="my-auto px-2 py-2 font-bold">Speed:</label>
          <select
            value={speed}
            onChange={(e) => setSpeed(e.target.value)}
            className="p-1 border rounded"
          >
            <option value="Fast">Fast</option>
            <option value="Normal">Normal</option>
            <option value="Slow">Slow</option>
          </select>
        </div>
      <div>
        <div id="section-nav">
          <Navbar
            sections={sections}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </div>
        {activeSection === "tasks" && (
          <TaskArea
            pets={pets}
            setHistory={setHistory}
            setActiveTasks={setActiveTasks}
          />
        )}
        {activeSection === "shop" && (
          <Shop
            coins={coins}
            setCoins={setCoins}
            setPets={setPets}
            pets={pets}
            setNavbarSections={setSections}
            itemsInShop={itemShop}
            setItemsInShop={setItemshop}
            addBreedingPen={addBreedingPen}
          />
        )}
        {sections.find((section) => section.name === "breeding") && (
          <div
            className={`${
              activeSection === "breeding" ? "block" : "hidden"
            } transition-all duration-500 ease-in-out`}
          >
            <Breeding
              pets={pets}
              setPets={setPets}
              setHistory={setHistory}
              breedPets={breedPets}
              breedingPens={breedingPens}
              setBreedingPens={setBreedingPens}
            />
          </div>
        )}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
      {pets.map((pet) => (
            <Pet
              key={pet.id}
              pet={pet}
              onFeed={handleFeedPet}
              onPlay={handlePlayPet}
              onSleep={handleSleepPet}
              onWakeUp={handleWakeUpPet}
              onRemove={handleRemovePet}
              speed={speed}
            />
          ))}
        </div>
        <div className="mt-2">
            <PetHistory history={history} />
        </div>
      </div>
    </div>
  );
};

export default CreatePet;
