export const TaskTypes = {
    COMPLETE: 'COMPLETE',
    MATH_QUESTION: 'MATH_QUESTION',
    POOP_SCOOP: 'POOP_SCOOP',
  };
  
  export const TaskTypeToString = (taskType) => {
    switch (taskType) {
      case TaskTypes.COMPLETE:
        return 'Complete Task';
      case TaskTypes.MATH_QUESTION:
        return 'Answer Math Question';
      case TaskTypes.POOP_SCOOP:
        return 'Clean the Poop';
      default:
        return 'Unknown Task';
    }
  };

  // Configuration for tasks affecting resources
export const TaskEffects = {
  [TaskTypes.COMPLETE]: {
    hunger: 0,
    happiness: 0,
    energy: 0.5,
    petTypes: ['Dog', 'Cat', 'Fish', 'Sheep'], // Affects all pets for now
  },
  [TaskTypes.MATH_QUESTION]: {
    hunger: 2,
    happiness: 1,
    energy: 1,
    petTypes: ['Dog'], // Customizable
  },
  [TaskTypes.POOP_SCOOP]: {
    hunger: 1,
    happiness: 2,
    energy: 1,
    petTypes: ['Cat'], // Customizable
  },
};