import React, { useState } from "react";
import { itemEffects } from "../../utils/shop-items";
import { useToast } from '../../ToastContext'; // Import the useToast hook

const Shop = ({
  coins,
  setCoins,
  pets,
  setPets,
  setNavbarSections,
  itemsInShop,
  setItemsInShop,
  addBreedingPen
}) => {
  const [purchaseFeedback, setPurchaseFeedback] = useState("");
  const addToast = useToast();

  const handlePurchase = (item) => {
    if (coins >= item.cost) {
      setCoins(coins - item.cost);
      const effects = itemEffects(pets, setPets, setNavbarSections, setItemsInShop, addBreedingPen);
      const selectedItemEffect = effects.find(effect => effect.name === item.name);
      if (selectedItemEffect) {
        selectedItemEffect.effect();
      }
      // Decrease quantity or remove item if quantity is 0
      setItemsInShop(prevItems =>
        prevItems.map(prevItem => 
          prevItem.name === item.name && prevItem.quantity > 0 
            ? { ...prevItem, quantity: prevItem.quantity - 1, cost: costRules(prevItem.cost) }
            : prevItem
        ).filter((shopItem) => shopItem.quantity > 0 || shopItem.quantity === null));

        addToast(`Purchased ${item.name} successfully!`, 'bg-green-800');
      setPurchaseFeedback(`Purchased ${item.name} successfully!`);
    } else {
      addToast(`Insufficient coins to buy ${item.name}.`, 'bg-red-600');
      setPurchaseFeedback(`Insufficient coins to purchase ${item.name}.`);
    }
  };

  const costRules = (cost) => {
    const increasedCost = cost * 1.5;
    return Math.ceil(increasedCost / 50) * 50;
  }

  return (
    <div className="shop bg-gray-100 p-4 rounded shadow-md mb-4 rounded-b-md">
      <h2 className="text-xl font-bold mb-4">Purchase event: {purchaseFeedback}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {itemsInShop.map((item) => (
          <div
            key={item.name}
            className={`shop-item bg-white p-4 rounded shadow hover:bg-gray-100 hover:shadow-lg cursor-pointer flex flex-col justify-between ${coins >= item.cost ? 'shadow-lg shadow-green-200 border-2 border-green-200' : ''}`}
            onClick={() => handlePurchase(item)}
          >
            <div>
              <h3 className="text-xl font-bold">{item.name}</h3>
              <p className="text-lg font-bold">Cost: {item.cost} coins</p>
              <p className="text-gray-600">{item.description}</p>
            </div>
            <p className="text-gray-600 mt-auto">Quantity: {item.quantity ? item.quantity : "Unlimited" }</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shop;
