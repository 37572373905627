import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import matter from "gray-matter";
import { ReactComponent as Dog } from "../../assets/dog.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";

window.Buffer = window.Buffer || require("buffer").Buffer;

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;

  useEffect(() => {
    const fetchPosts = async () => {
      const context = require.context("../../articles", false, /\.md$/);
      const articles = context.keys().map((key) => ({
        slug: key.match(/\.\/(.*)\.md$/)[1],
        path: context(key),
      }));

      const parsedPosts = await Promise.all(
        articles.map(async (article) => {
          const response = await fetch(article.path);
          const text = await response.text();
          const { data } = matter(text);
          return {
            title: data.title,
            summary: data.summary,
            date: new Date(data.date),
            slug: article.slug,
          };
        })
      );

      // Assign incremented IDs based on sorted order
      parsedPosts.forEach((post, index) => {
        post.id = index + 1;
      });

      // Sort posts by date (descending)
      parsedPosts.sort((a, b) => b.date - a.date);

      setPosts(parsedPosts);
    };

    fetchPosts();
  }, []);

  const totalPages = Math.ceil(posts.length / postsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Blog</h1>
      <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0, 0)">
          <Logo />
        </g>
        <g transform="translate(50, 50)">
          <Dog />
        </g>
      </svg>
      <ul className="space-y-4">
        {currentPosts.map((post) => (
          <li
            key={post.id}
            className="border p-4 rounded shadow hover:bg-gray-100"
          >
            <Link to={`/blog/${post.id}`}>
              <p className="text-gray-400">{post.date.toLocaleDateString()}</p>
              <h2 className="text-2xl">{post.title}</h2>
              <p className="text-gray-600">{post.summary}</p>
            </Link>
          </li>
        ))}
      </ul>
      <div className="flex justify-between mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <span className="text-xl">
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Blog;
