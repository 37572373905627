export const itemEffects = (pets, setPets, setNavbarSections, setItemsInShop, addBreedingPen) => [
  {
    name: "Fast Food Delivery",
    effect: () => {
      setPets(
        pets.map((pet) => ({
          ...pet,
          hunger: Math.min(pet.hunger + 50, 100),
          energy: Math.max(pet.energy - 20, 0),
        }))
      );
    },
  },
  {
    name: "Adrenaline",
    effect: () => {
      setPets(
        pets.map((pet) => ({
          ...pet,
          hunger: Math.max(pet.hunger - 20, 0),
          energy: Math.min(pet.energy + 50, 100),
        }))
      );
    },
  },

  {
    name: "Happy Pills",
    effect: () => {
      setPets(
        pets.map((pet) => ({
          ...pet,
          happiness: Math.min(pet.happiness + 50, 100),
          hunger: Math.max(pet.hunger - 10, 0),
          energy: Math.max(pet.energy - 10, 0),
        }))
      );
    },
  },
  {
    name: "Breeding Pen",
    effect: () => {
      setNavbarSections((prevSections) => [
        ...prevSections,
        { name: "breeding", label: "Breeding", count: 0 },
      ]);
      addBreedingPen();
      setItemsInShop((prevItems) => [...prevItems,
        itemData.BreedingPenUpgrade
      ]);
    },
  },
  {
    name: itemData.BreedingPenUpgrade.name,
    effect: () => {
      addBreedingPen();
    }
  }
];

export const itemData = {
  FastFoodDelivery: {
    name: "Fast Food Delivery",
    description: "Fast food delivery for your pets. Not very healthy, energy will drop.",
    cost: 5,
    quantity: null,
  },
  HappyPills: {
    name: "Happy Pills",
    description: "Pills to put a smile on your pets face, but at the cost of hunger and energy.",
    cost: 5,
    quantity: null,
  },
  Adrenaline: {
    name: "Adrenaline",
    description: "Give your pets a boost of energy, but they will get hungry.",
    cost: 5,
    quantity: null,
  },
  BreedingPen: {
    name: "Breeding Pen",
    description: "An area for your pets to make babies.",
    cost: 50,
    quantity: 1,
  },
  BreedingPenUpgrade: {
    name: "Breeding Pen Slot Upgrade",
    description: "An additional slot in your pen",
    cost: 100, // The cost should increase with each purchase as n = n-1 + n-2
    quantity: 9,
  },
}

export const initialItemData = () => [itemData.FastFoodDelivery, itemData.HappyPills, itemData.Adrenaline, itemData.BreedingPen];