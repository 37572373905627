export const sizeChart = Object.freeze({
  micro: 0,
  extra_small: 1,
  small: 2,
  medium: 3,
  large: 4,
  extra_large: 5,
});

export const PetData = {
  Dog: {
    size: sizeChart.medium,
    breedBase: 0.1,
    breedAttemptIncrease: 0.1,
  },
  Cat: {
    size: sizeChart.small,
    breedBase: 0.1,
    breedAttemptIncrease: 0.1,
  },
  Fish: {
    size: sizeChart.extra_small,
    breedBase: 0.05,
    breedAttemptIncrease: 0.05,
  },
  Sheep: {
    size: sizeChart.medium,
    breedBase: 0.15,
    breedAttemptIncrease: 0.05,
  },
};

export const CalculateBreedSuccessRate = (baseTypes) => {
  const totalBreedBase = baseTypes.reduce((total, baseType) => {
    return total + PetData[baseType].breedBase;
  }, 0);

  return totalBreedBase / baseTypes.length;
};

export const CalculateBreedAttemptincrease = (baseTypes) => {
    const totalBreedAttemptincrease = baseTypes.reduce((total, baseType) => {
      return total + PetData[baseType].breedAttemptIncrease;
    }, 0);
  
    return totalBreedAttemptincrease / baseTypes.length;
  };

export const CalculateSize = (baseTypes) => {
  const totalsize = baseTypes.reduce((total, baseType) => {
    return total + PetData[baseType].size;
  }, 0);

  return totalsize / baseTypes.length;
};
