export const TestPets = {
    Pet1: {
      name: 'Adult Pet 1',
      type: 'Dog',
      color: '#ff0000',
      age: 21,
      ageGroup: 'Adult',
      isSleeping: false,
      isDeathLogged: false,
    },
    Pet2: {
      name: 'Adult Pet 2',
      type: 'Cat',
      color: '#0000ff',
      age: 22,
      ageGroup: 'Adult',
      isSleeping: false,
      isDeathLogged: false,
    },
    Pet3: {
      name: 'Adult Pet 3',
      type: 'Fish',
      color: '#bbbbff',
      age: 22,
      ageGroup: 'Adult',
      isSleeping: false,
      isDeathLogged: false,
    },
    Pet4: {
      name: 'Adult Pet 4',
      type: 'Sheep',
      color: '#dcdabf',
      age: 22,
      ageGroup: 'Adult',
      isSleeping: false,
      isDeathLogged: false,
    },
  };