import { ReactComponent as CatHead } from "../assets/cat/head.svg";
import { ReactComponent as CatBody } from "../assets/cat/body.svg";
import { ReactComponent as CatTail } from "../assets/cat/tail.svg";
import { ReactComponent as CatFeet } from "../assets/cat/feet.svg";
import { ReactComponent as FishHead } from "../assets/fish/head.svg";
import { ReactComponent as FishBody } from "../assets/fish/body.svg";
import { ReactComponent as FishTail } from "../assets/fish/tail.svg";
import { ReactComponent as SheepHead } from "../assets/sheep/head.svg";
import { ReactComponent as SheepBody } from "../assets/sheep/body.svg";
import { ReactComponent as SheepFeet } from "../assets/sheep/feet.svg";
import { ReactComponent as DogFeet } from "../assets/dog/feet.svg";
import { ReactComponent as DogBody } from "../assets/dog/body.svg";
import { ReactComponent as DogHead } from "../assets/dog/head.svg";
import { ReactComponent as DogTail } from "../assets/dog/tail.svg";

export const CatSvg = () => {
  return (
    <svg width="110" height="110" xmlns="http://www.w3.org/1100/svg">
      <g transform="scale(10) translate(0, 0)">
        <CatHead />
      </g>
      <g transform="scale(10) translate(1, 4)">
        <CatBody />
      </g>
      <g transform="scale(10) translate(6.9, 0.7)">
        <CatTail />
      </g>
      <g transform="scale(10) translate(1.5, 7.6)">
        <CatFeet />
      </g>
    </svg>
  );
};

export const FishSvg = () => {
  return (
    <svg width="110" height="110" xmlns="http://www.w3.org/1100/svg">
      <g transform="scale(10) translate(1, 4)">
        <FishHead />
      </g>
      <g transform="scale(10) translate(4.3, 4)">
        <FishBody />
      </g>
      <g transform="scale(10) translate(8, 4)">
        <FishTail />
      </g>
    </svg>
  );
};

export const CatFishSvg = () => {
  return (
    <svg width="110" height="110" xmlns="http://www.w3.org/1100/svg">
      <g transform="scale(8) translate(2, 5.7)">
        <CatFeet />
      </g>
      <g transform="scale(10) translate(4, 1.1)">
        <FishBody />
      </g>
      <g transform="scale(10) translate(7.5, 1)">
        <FishTail />
      </g>
      <g transform="scale(10) translate(0, 0)">
        <CatHead />
      </g>
    </svg>
  );
};

export const SheepSvg = () => {
  return (
    <svg width="110" height="110" xmlns="http://www.w3.org/1100/svg">
      <g transform="scale(12) translate(1.2, 2.2)">
        <SheepBody />
      </g>
      <g transform="scale(12) translate(2.2, 6.7)">
        <SheepFeet />
      </g>
      <g transform="scale(12) translate(0.9, 1.1)" fill="red">
        <SheepHead />
      </g>
    </svg>
  );
};

export const DogSvg = () => {
  return (
    <svg width="110" height="110" xmlns="http://www.w3.org/1100/svg">
      <g transform="scale(10) translate(1.1, 4)">
        <DogBody />
      </g>
      <g transform="scale(10) translate(0, 0)">
        <DogHead />
      </g>
      <g transform="scale(10) translate(9.1, 3.3)">
        <DogTail />
      </g>
      <g transform="scale(10) translate(1.5, 7.6)">
        <DogFeet />
      </g>
    </svg>
  );
};

export const HeadSVG = ({ head }) => {
  switch (head) {
    case 'Cat':
      return <CatHead />;
    case 'Dog':
      return <DogHead />;
    case 'Fish':
      return <FishHead />;
    case 'Sheep':
      return <SheepHead />;
    default:
      return null; // Handle the default case to avoid errors
  }
};

export const BodySVG = ({ head }) => {
  switch (head) {
    case 'Cat':
      return <CatBody/>;
    case 'Dog':
      return <DogBody/>;
    case 'Fish':
      return <FishBody/>;
    case 'Sheep':
      return <SheepBody/>;
    default:
      return null; // Handle the default case to avoid errors
  }
};

export const FeetSVG = ({ head }) => {
  switch (head) {
    case 'Cat':
      return <CatFeet/>;
    case 'Dog':
      return <DogFeet/>;
    case 'Fish':
      return null;
    case 'Sheep':
      return <SheepFeet/>;
    default:
      return null; // Handle the default case to avoid errors
  }
};

export const TailSVG = ({ head }) => {
  switch (head) {
    case 'Cat':
      return <CatTail/>;
    case 'Dog':
      return <DogTail/>;
    case 'Fish':
      return <FishTail/>;
    case 'Sheep':
      return null;
    default:
      return null; // Handle the default case to avoid errors
  }
};

export const ANIMAL_ANCHOR_POINTS = {
  Cat: {
    body: { svg: <CatBody/>, x: 1, y: 4, scale: 10 },
    head: { svg: <CatHead/>, x: 0, y: 0, scale: 10 },
    tail: { svg: <CatTail/>, x: 6.9, y: 0.7, scale: 10 },
    feet: { svg: <CatFeet/>, x: 1.5, y: 7.6, scale: 10 }
  },
  Dog: {
    body: { svg: <DogBody/>, x: 1.1, y: 4, scale: 10 },
    head: { svg: <DogHead/>, x: 0, y: 0, scale: 10 },
    tail: { svg: <DogTail/>, x: 9.1, y: 3.3, scale: 10 },
    feet: { svg: <DogFeet/>, x: 1.5, y: 7.6, scale: 10 }
  },
  Sheep: {
    body: { svg: <SheepBody/>, x: 1.2, y: 2.2, scale: 12 },
    head: { svg: <SheepHead/>, x: 0.9, y: 1.1, scale: 12 },
    tail: { svg: null, x: 0, y: 0, scale: 12 },
    feet: { svg: <SheepFeet/>, x: 2.2, y: 6.7, scale: 12 }
  },
  Fish: {
    body: { svg: <FishBody/>, x: 4.3, y: 4, scale: 10 },
    head: { svg: <FishHead/>, x: 1, y: 4, scale: 10 },
    tail: { svg: <FishTail/>, x: 8, y: 4, scale: 10 }
  }
}

export const BodyParts = [
  "body",
  "head",
  "tail",
  "feet"
]