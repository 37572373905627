import { syllable } from "syllable";

export const combineNames = (animal1, animal2) => {
  const splitBySyllables = (word, numSyllables) => {
    const digraphs = ["th", "sh", "ch", "ph", "wh"];
    const blends = [
      "bl",
      "cl",
      "tr",
      "st",
      "str",
      "br",
      "dr",
      "fl",
      "gl",
      "gr",
      "pl",
      "pr",
      "sl",
      "sp",
      "sc",
      "sk",
      "sm",
      "sn",
      "sw",
      "tw",
      "dw",
      "tw",
      "kw",
    ];

    let count = 0;
    for (let i = 0; i < word.length; i++) {
      if ("aeiouy".includes(word[i])) {
        count++;
      }
      if (count === numSyllables) {
        let splitPoint = i + 1;

        // Check for double consonants
        if (i + 2 < word.length && word[i + 1] === word[i + 2]) {
          splitPoint = i + 2;
        }

        // Check for digraphs
        if (i + 2 < word.length && digraphs.includes(word[i + 1] + word[i + 2])) {
          splitPoint = i + 2;
        }

        // Check for blends
        if (i + 2 < word.length && blends.includes(word[i + 1] + word[i + 2])) {
          splitPoint = i + 2;
        }

        // Ensure 'r' stays with the previous syllable
        if (i + 1 < word.length && word[i + 1] === "r") {
          splitPoint = i + 2;
        }

        return [word.slice(0, splitPoint), word.slice(splitPoint)];
      }
    }
    return [word, ""];
  };

  animal2 = animal2.toLowerCase();
  const syllables1 = syllable(animal1);
  const syllables2 = syllable(animal2);

  let combinedName = "";

  if (syllables1 === 1) {
    if (syllables2 > 1) {
      const [, split2b] = splitBySyllables(animal2, Math.floor(syllables2 / 2));
      combinedName = animal1 + split2b;
    } else {
      combinedName = animal1 + animal2;
    }
  } else {
    const split1Count = Math.ceil(syllables1 / 2);
    const remainingSyllables = syllables1 - split1Count;

    const [split1a] = splitBySyllables(animal1, split1Count);

    if (syllables2 === 1) {
      combinedName = split1a + animal2;
    } else {
      const [, split2b] = splitBySyllables(
        animal2,
        remainingSyllables > 0 ? remainingSyllables : 1
      );
      if (
        "aeiouy".includes(split1a[split1a.length - 1]) &&
        "aeiouy".includes(split2b[0])
      ) {
        combinedName = split1a.slice(0, -1) + split2b;
      } else {
        combinedName = split1a + split2b;
      }
    }
  }

  return combinedName;
};
