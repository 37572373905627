import React, { useState, useEffect } from "react";
import petNames from "../../assets/pet-names.json";
import { generateRandomName } from "../../utils/utils";

const BreedingModal = ({
  pets,
  tempSelectedPets,
  setTempSelectedPets,
  onConfirm,
  onClose,
}) => {
  const [parent1, setParent1] = useState(tempSelectedPets[0] || null);
  const [parent2, setParent2] = useState(tempSelectedPets[1] || null);
  const [newPetName, setNewPetName] = useState("");

  useEffect(() => {
    generateRandomName(petNames, setNewPetName);
  }, []);

  const handleParent1Change = (e) => {
    const selectedPet = pets.find((pet) => pet.id === e.target.value);
    setParent1(selectedPet);
    if (parent2 && parent2.id === selectedPet.id) {
      setParent2(null);
    }
  };

  const handleParent2Change = (e) => {
    const selectedPet = pets.find((pet) => pet.id === e.target.value);
    setParent2(selectedPet);
    if (selectedPet && parent1 && parent1.id === selectedPet.id) {
      setParent1(null);
    }
  };

  const handleConfirm = () => {
    if (parent1 && parent2) {
      setTempSelectedPets([parent1, parent2]);
      onConfirm([parent1, parent2], newPetName); // Pass baby name to onConfirm
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Breed Pets</h2>
          <button
            onClick={onClose}
            className="text-xl font-bold text-gray-400 hover:text-gray-900"
          >
            X
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            New Pet Name
          </label>
          <div className="flex mt-2">
            <input
              type="text"
              value={newPetName}
              onChange={(e) => setNewPetName(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
            <button
              type="button"
              onClick={() => generateRandomName(petNames, setNewPetName)}
              className="bg-blue-500 text-white px-4 py-2 rounded ml-2 hover:bg-blue-600"
            >
              Randomize
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Parent 1</label>
          <select
            value={parent1 ? parent1.id : ""}
            onChange={handleParent1Change}
            className="w-full p-2 border rounded mt-2"
          >
            <option value="">Select Parent 1</option>
            {pets
              .filter((pet) => pet.age > 18)
              .map((pet) => (
                <option key={pet.id} value={pet.id}>
                  {pet.name}
                </option>
              ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Parent 2</label>
          <select
            value={parent2 ? parent2.id : ""}
            onChange={handleParent2Change}
            disabled={!parent1}
            className="w-full p-2 border rounded mt-2"
          >
            <option value="">Select Parent 2</option>
            {pets
              .filter(
                (pet) => (pet.age > 18 && (!parent1 || pet.id !== parent1.id)
              ))
              .map((pet) => (
                <option key={pet.id} value={pet.id}>
                  {pet.name}
                </option>
              ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleConfirm}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
          >
            Confirm
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BreedingModal;
