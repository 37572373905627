import React, { createContext, useContext, useState } from "react";

// Create Context
const ToastContext = createContext();

// Custom hook to use the Toast Context
export const useToast = () => {
  return useContext(ToastContext);
};

// Provider component
export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (message, colour) => {
    const id = Date.now(); // Unique ID for each toast
    setToasts((prevToasts) => [...prevToasts, { id, message, colour }]);
    // Remove toast after 3 seconds
    setTimeout(() => removeToast(id), 10000);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
        {toasts.map((toast) => (
          <div
            key={toast.id}
            className={`${toast.colour ?? "bg-blue-500"} text-white px-3 py-3 rounded mb-2 shadow-lg animate-fade-in-out"`}
          >
            <button
              onClick={() => removeToast(toast.id)}
              className="block top-0 right-0 mr-1 text-md px-2 font-bold text-white hover:text-gray-200 focus:outline-none float-right"
            >
              ✕
            </button>
            <span className="block">{toast.message}</span>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
