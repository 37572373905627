import { useState, useEffect } from "react";
import gameSettings from "../game-settings";
import { getAgeGroup, logEvent, mixColors } from "../utils/utils";
import { TaskEffects } from "../utils/task-types";
import { CalculateBreedSuccessRate, CalculateSize } from "../utils/pet-data";
import { v4 as uuidv4 } from "uuid";
import { combineNames } from "../utils/pet-name-merge";
import { useToast } from '../ToastContext'; // Import the useToast hook

const usePets = (setHistory, setCoins, speed, activeTasks) => {
  const [pets, setPets] = useState(() => {
    const savedPets = localStorage.getItem("pets");
    return savedPets ? JSON.parse(savedPets) : [];
  });

  const addToast = useToast();
  
  useEffect(() => {
    const depletionInterval = setInterval(() => {
      setPets((prevPets) =>
        prevPets
          .map((pet) => {
            let hungerIncrease = gameSettings.depletionRates.hunger;
            let happinessDecrease = gameSettings.depletionRates.happiness;
            let energyDecrease = gameSettings.depletionRates.energy;

            activeTasks.forEach((task) => {
              const effect = TaskEffects[task.type];
              if (effect && effect.petTypes.includes(pet.type)) {
                hungerIncrease += effect.hunger;
                happinessDecrease += effect.happiness;
                energyDecrease += effect.energy;
              }
            });

            if (
              (pet.hunger === 0 || pet.happiness === 0 || pet.energy === 0) &&
              !pet.isDeathLogged
            ) {
              const causeOfDeath =
                pet.hunger === 0
                  ? "hunger"
                  : pet.happiness === 0
                  ? "happiness"
                  : "energy";
              logEvent(
                `Pet ${pet.name} died of ${causeOfDeath} at age ${pet.age}.`,
                setHistory
              );
              addToast(`Your ${pet.type} called ${pet.name} has died`, 'bg-red-800');
              return { ...pet, isDead: true, isDeathLogged: true };
            }
            const ageGroup = getAgeGroup(pet.age, gameSettings.ageGroups);
            return {
              ...pet,
              ageGroup,
              hunger: Math.max(pet.hunger - hungerIncrease, 0),
              happiness: Math.max(pet.happiness - happinessDecrease, 0),
              energy: pet.isSleeping
                ? Math.min(
                    pet.energy + gameSettings.depletionRates.energyIncrease,
                    100
                  )
                : Math.max(pet.energy - energyDecrease, 0),
            };
          })
          .filter((pet) => !pet.isDead)
      );
    }, 1000 / gameSettings.speeds[speed]);

    return () => clearInterval(depletionInterval);
  }, [speed, setHistory, activeTasks]);

  useEffect(() => {
    const ageInterval = setInterval(() => {
      setPets((prevPets) =>
        prevPets.map((pet) => {
          const newAge = pet.age + 1;
          const ageGroup = getAgeGroup(newAge, gameSettings.ageGroups);
          return {
            ...pet,
            age: newAge,
            ageGroup,
          };
        })
      );
      setCoins((prevCoins) => prevCoins + pets.length); // Increment coins based on number of pets
    }, gameSettings.ageInterval / gameSettings.speeds[speed]);

    return () => clearInterval(ageInterval);
  }, [speed, pets.length, setCoins]);

  useEffect(() => {
    localStorage.setItem("pets", JSON.stringify(pets));
  }, [pets]);

  const addPet = (newPet) => {
    var baseTypes = newPet.baseTypes ?? [newPet.type];
    setPets((prevPets) => [
      ...prevPets,
      {
        ...newPet,
        energy: 100,
        happiness: 100,
        hunger: 100,
        id: uuidv4(),
        baseTypes: baseTypes,
        age: newPet.age ?? 20,
        breedSuccessRate: CalculateBreedSuccessRate(baseTypes),
        size: CalculateSize(baseTypes),
      },
    ]);

    addToast(`You bought a new ${newPet.type} called ${newPet.name}`, 'bg-pink-600');
  };

  const breedPets = (pet1, pet2, newPetName) => {
    const newColor = mixColors(pet1.color, pet2.color);
    const newPetType =
      pet1.type === pet2.type ? pet1.type : combineNames(pet1.type, pet2.type);
    const newPet = {
      name: newPetName,
      type: newPetType,
      baseTypes: [...new Set([...pet1.baseTypes, ...pet2.baseTypes])],
      color: newColor,
      age: 0,
      ageGroup: "Baby",
      isSleeping: false,
      isDeathLogged: false,
      parentsNames: `${pet1.name} and ${pet2.name}`,
      parentIds: [pet1.id, pet2.id],
    };
    addPet(newPet);
    logEvent(
      `Pet ${newPet.name} was born to parents ${newPet.parentsNames}.`,
      setHistory
    );
    addToast(`${newPet.parentsNames} have made a baby ${newPet.type} called ${newPet.name}`, 'bg-pink-600');
  };

  const handleFeedPet = (petId) => {
    setPets((prevPets) =>
      prevPets.map((pet) =>
        pet.id === petId
          ? {
              ...pet,
              hunger: Math.min(pet.hunger + gameSettings.increments.feed, 100),
            }
          : pet
      )
    );
  };

  const handlePlayPet = (petId) => {
    setPets((prevPets) =>
      prevPets.map((pet) =>
        pet.id === petId
          ? {
              ...pet,
              happiness: Math.min(
                pet.happiness + gameSettings.increments.play.happiness,
                100
              ),
              energy: Math.max(
                pet.energy + gameSettings.increments.play.energy,
                0
              ),
            }
          : pet
      )
    );
  };

  const handleSleepPet = (petId) => {
    setPets((prevPets) =>
      prevPets.map((pet) =>
        pet.id === petId ? { ...pet, isSleeping: true } : pet
      )
    );
  };

  const handleWakeUpPet = (petId) => {
    setPets((prevPets) =>
      prevPets.map((pet) =>
        pet.id === petId ? { ...pet, isSleeping: false } : pet
      )
    );
  };

  const handleRemovePet = (petId) => {
    setPets((prevPets) => prevPets.filter((pet) => pet.id !== petId));
  };

  return {
    pets,
    setPets,
    addPet,
    breedPets,
    handleFeedPet,
    handlePlayPet,
    handleSleepPet,
    handleWakeUpPet,
    handleRemovePet,
  };
};

export default usePets;
