import React from "react";
import { v4 as uuidv4 } from "uuid";
import { TestPets } from "./test-data";
import { logEvent } from "../../utils/utils";
import { useToast } from '../../ToastContext'; // Import the useToast hook

export const TestArea = ({ addPet, resetData, setHistory, addBreedingPen }) => {
  const addToast = useToast(); // Get the addToast function from the context

  const testToast = () => {
    addToast('Test toast thats longer than a small message');
  };
  const handleCreateFourAdultPets = () => {
    addPet({ ...TestPets.Pet1, id: uuidv4(), age: 21, ageGroup: "Adult" }); // Added id
    addPet({ ...TestPets.Pet2, id: uuidv4(), age: 22, ageGroup: "Adult" }); // Added id
    addPet({ ...TestPets.Pet3, id: uuidv4(), age: 22, ageGroup: "Adult" }); // Added id
    addPet({ ...TestPets.Pet4, id: uuidv4(), age: 23, ageGroup: "Adult" }); // Added id
    logEvent(
      `Pet ${TestPets.Pet1.name}, ${TestPets.Pet2.name} and ${TestPets.Pet3.name} were created for testing.`,
      setHistory
    );
  };

  return (
    <div className="test-area p-2 m-2 bg-yellow-200">
        <span className="mr-2">Test Tools</span>
      <button
        onClick={resetData}
        className="bg-red-500 text-white px-4 py-2 rounded"
      >
        Reset Data
      </button>
      <button
        onClick={handleCreateFourAdultPets}
        className="bg-blue-500 text-white px-4 py-2 rounded ml-2"
      >
        Create Four Adult Pets
      </button>
      <button
        onClick={addBreedingPen}
        className="bg-green-500 text-white px-4 py-2 rounded mb-4"
      >
        Add Breeding Pen
      </button>
      <button
      onClick={testToast}
        className="bg-yellow-300 text-white px-4 py-2 rounded mb-4"
      >
        Test Toast
      </button>
    </div>
  );
};
