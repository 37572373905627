import React from 'react';

const CompleteTaskModal = ({ task, onComplete, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Complete Task</h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900">&times;</button>
        </div>
        <p className="mb-4">Task: {task.title}</p>
        <button
          onClick={onComplete}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Complete
        </button>
      </div>
    </div>
  );
};

export default CompleteTaskModal;
